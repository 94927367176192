// extracted by mini-css-extract-plugin
export var CRODemoTitleStylesOverride = "orders-module--CRODemoTitleStylesOverride--W65Y5";
export var academiaDemoLeftSectionOverride = "orders-module--academiaDemoLeftSectionOverride--okdH1";
export var academiaDemoSubtitleStylesOverride = "orders-module--academiaDemoSubtitleStylesOverride--a1DHy";
export var academiaDemoTitleStylesOverride = "orders-module--academiaDemoTitleStylesOverride--mOvDi";
export var antibodiesDemoLeftSectionOverride = "orders-module--antibodiesDemoLeftSectionOverride--d3gNo";
export var cardOverride = "orders-module--cardOverride--dYsdT";
export var cardWrapper = "orders-module--cardWrapper--cqusU";
export var cards = "orders-module--cards--C93s4";
export var clinicalDemoLeftSectionOverride = "orders-module--clinicalDemoLeftSectionOverride--yqaPz";
export var clinicalDemoSubtitleStylesOverride = "orders-module--clinicalDemoSubtitleStylesOverride--dqMbL";
export var clinicalDemoTitleStylesOverride = "orders-module--clinicalDemoTitleStylesOverride--9vUZg";
export var clinicalGetADemoOverride = "orders-module--clinicalGetADemoOverride--bOa0y";
export var containerOverride = "orders-module--containerOverride--fX32s";
export var demoMargins = "orders-module--demoMargins--LRfiv";
export var demoSubtitleStylesOverride = "orders-module--demoSubtitleStylesOverride--B1Pyg";
export var demoTitleStylesOverride = "orders-module--demoTitleStylesOverride--MZVm1";
export var featureCardsMargins = "orders-module--featureCardsMargins--EfNwr";
export var featureMargins = "orders-module--featureMargins---tQJy";
export var featureQuickAccess = "orders-module--featureQuickAccess--86-Tz";
export var featureQuickAccessSpacing = "orders-module--featureQuickAccessSpacing--ionHt";
export var featureSummaryContentOverride = "orders-module--featureSummaryContentOverride--TlHvC";
export var featureSummarySectionWrapper = "orders-module--featureSummarySectionWrapper--t9SvR";
export var featureSummarySubtitle = "orders-module--featureSummarySubtitle--ikSeU";
export var featureSummaryTitle = "orders-module--featureSummaryTitle--IYyrS";
export var featureTitleStylesOverride = "orders-module--featureTitleStylesOverride--RPjxD";
export var featuresLayout = "orders-module--featuresLayout--WIVBo";
export var getADemoOverride = "orders-module--getADemoOverride--Qi1Jg";
export var getWorkDoneMargins = "orders-module--getWorkDoneMargins--ExbrN";
export var getWorkSectionWrapper = "orders-module--getWorkSectionWrapper--0xZbs";
export var headerOverride = "orders-module--headerOverride--odt8r";
export var institutionsMargins = "orders-module--institutionsMargins--PKLIO";
export var institutionsOuterContainerClassName = "orders-module--institutionsOuterContainerClassName--Gp0ue";
export var integrated_bg = "orders-module--integrated_bg--TKvnQ";
export var itemVariantOverride = "orders-module--itemVariantOverride--k72B+";
export var joinInstitutions = "orders-module--joinInstitutions--Wkk8E";
export var joinInstitutionsContainerOverride = "orders-module--joinInstitutionsContainerOverride--Blamf";
export var joinInstitutionsContainerWrapper = "orders-module--joinInstitutionsContainerWrapper--C2vuw";
export var joinInstitutionsWrapper = "orders-module--joinInstitutionsWrapper--wiJJl";
export var labConsumablesWrapper = "orders-module--labConsumablesWrapper--GMXdt";
export var layout = "orders-module--layout--GiWu3";
export var leftSectionOverride = "orders-module--leftSectionOverride--+WAWq";
export var limitWidthOnLargeScreens = "orders-module--limitWidthOnLargeScreens--28PMS";
export var link = "orders-module--link--OUzzt";
export var listClassNameOverride = "orders-module--listClassNameOverride--U6u+J";
export var margins = "orders-module--margins--Z+vnC";
export var ourPartners = "orders-module--ourPartners--x8n+D";
export var rightSectionStylesOverride = "orders-module--rightSectionStylesOverride--iQSom";
export var startupDemoFeatureSummaryTitle = "orders-module--startupDemoFeatureSummaryTitle--bq4m3";
export var startupDemoSubtitleStylesOverride = "orders-module--startupDemoSubtitleStylesOverride--YXO6B";
export var startupFeatureQuickAccess = "orders-module--startupFeatureQuickAccess--ar34o";
export var startupGetWorkSectionWrapper = "orders-module--startupGetWorkSectionWrapper--39FHi";
export var startupListStyles = "orders-module--startupListStyles--xzVPo";
export var thousandOfInsittutions = "orders-module--thousandOfInsittutions--5PaCp";
export var titleOverride = "orders-module--titleOverride--5MDM7";
export var titleStylesOverride = "orders-module--titleStylesOverride--yfLt2";